import React from "react";
import Navbar from "./Navbar"; // Import Navbar
import Footer from "./Footer"; // Import Footer

const ThankYou = () => {
  return (
    <div className="container">
      <Navbar  style={{
          position: "relative",   // Reset the position
          backgroundColor: "#f8f9fa", // Change background color (optional)
          padding: "10px",        // Adjust padding (optional)
        }} /> {/* Include Navbar */}
      
      <div className="thank-you">
        <h1>Thank You for Your Purchase!</h1>
        <p>Your payment has been successfully processed.</p>
        <p>We appreciate your business and hope to see you again!</p>
      </div>
      
      <Footer /> {/* Include Footer */}
    </div>
  );
};

export default ThankYou;

