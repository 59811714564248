import React from "react";

import { useGlobalHandlers } from "./GlobalHandlersContext";

function CartItem({ cartItem }) {
  const { setitemcount } = useGlobalHandlers();

  const { product_id, title, price , offer_price, photos, count } = cartItem;
  const item_price = offer_price * count;
  return (
    <div className="cart-item">
      <div className="cart-image">
        <img src={photos[0]} alt="" />
      </div>
      <div className="item-details">
        <div className="cart-title">
          <h5>{title}</h5>
        </div>
        <div className="cart-price">
          <small>&#8377;{offer_price}</small>
        </div>
        <div className="cart-count">
          <button
            onClick={() => {
              setitemcount(product_id, -1);
            }}
          >
            -
          </button>
          <p>{count}</p> <button onClick={() => setitemcount(product_id, 1)}>+</button>
        </div>
      </div>
      <div className="item-total-price">
        <p>&#8377;{item_price}</p>
      </div>
    </div>
  );
}

export default CartItem;
