import React, { useEffect, useState } from "react";
import CartItem from "./CartItem";
import { useMyContext } from "./Context";
function Cart() {
  const { cart, user } = useMyContext();

  // const key = "rzp_live_TINqVny7E4uUoh"; // Replace with your Test Key ID
  const key = "rzp_test_p8GGhb0KGX01VT"; // Replace with your Test Key ID

  function handleprice() {
    let ans = 0;
    cart.map((item) => (ans += item.offer_price * item.count));
    setPrice(ans);
    const widgetConfig = {
      key: key,
      amount: ans * 100, // Set the amount in paise
    };
    /*global  RazorpayAffordabilitySuite*/
    // Initialize the Razorpay Affordability Suite
    const rzpAffordabilitySuite = new RazorpayAffordabilitySuite(widgetConfig);
    rzpAffordabilitySuite.render();
  }
  const [price, setPrice] = useState(0);
  const [order, setOrder] = useState({});
  //run once
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(order).length === 0) return;
    var options = {
      // key: "rzp_live_TINqVny7E4uUoh", // Enter the Key ID generated from the Dashboard
      key: key, // Enter the Key ID generated from the Dashboard
      amount: order.razorpay_order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Parei Foods",
      description: "Pickle",
      image: "https://testproductphotos.s3.ap-south-1.amazonaws.com/logo.png",
      order_id: order.razorpay_order.id,
      send_sms_hash : true,
      //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        const paymentId = response.razorpay_payment_id;
        const orderId = response.razorpay_order_id;
        const signature = response.razorpay_signature;
        console.log(order.user_id);

        // Send payment details to your backend for verification
        fetch("https://id4f34hzse.execute-api.ap-south-1.amazonaws.com/default/verify_payment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "*/*",
          },
          body: JSON.stringify({
            paymentId,
            orderId,
            signature, email : order.user_id
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data)
            if (data.success) {
              console.log("Payment verified successfully!");
              setOrder({});
              window.location.href = '/thank-you';
            } else {
              console.log("Payment verification failed.");
            }
          })
          .catch((err) => {
            console.error("Error verifying payment:", err);
          });
      },
      prefill: {
        name: order.user_name,
        email: order.user_id,
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    /*global Razorpay */
    var rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    document.getElementById("rzp-button1").onclick = function (e) {
      rzp1.open();
      e.preventDefault();
    };
    document.getElementById("rzp-button1").click();
  }, [order]);

  //run everytime the app re-renders
  useEffect(() => {
    handleprice();
  });

  function checkout() {
    fetch(
      " https://id4f34hzse.execute-api.ap-south-1.amazonaws.com/default/orders",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Accept: "*/*",
        },
        body: JSON.stringify({
          user_id: JSON.parse(user).email,
          user_name: JSON.parse(user).name,
          items: cart,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setOrder(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="cart-container" hidden>
      <h3>{}</h3>
      <div>
        {cart.length > 0 &&
          cart.map((cartItem) => (
            <CartItem key={cartItem.id} cartItem={cartItem} />
          ))}
      </div>
      <div className="total-price">
        <h5>Total Price</h5>
        <p>&#8377;{price}</p>
      </div>

      <div className="delivery-address">
        <h5>Delivery Address</h5>
        <p>&#8377;{price}</p>
      </div>

      <div className="checkout-btn">
        <button className="btn-accent" onClick={checkout}>
          Checkout
        </button>
        <button hidden id="rzp-button1">
          Pay
        </button>
      </div>
      <div id="razorpay-affordability-widget"> </div>
    </div>
  );
}

export default Cart;
