
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AboutUs from "./Components/AboutUs";
import { MyContextProvider } from "./Components/Context";
import { GlobalHandlersProvider } from "./Components/GlobalHandlersContext";
import Home from "./Components/Home";
import Products from "./Components/Products";
import ThankYou from "./Components/ThankYou";
import Orders from "./Components/Orders";

function App() {


  return (
    <MyContextProvider>
      <GlobalHandlersProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="products" element={<Products />} />
              <Route path="about" element={<AboutUs />} />
              <Route path="/product/:id" element={<Products />}></Route>
              <Route path="/terms" element={<Products />}></Route>
              <Route path="/privacy" element={<Products />}></Route>
              <Route path="/refundpolicies" element={<Products />}></Route>
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/orders" element={<Orders />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </GlobalHandlersProvider>
    </MyContextProvider>
  );
}

export default App;
