import React, { useEffect, useState } from 'react';
import { useMyContext } from './Context'; // Adjust the path to your context file
import Navbar from './Navbar';
import Footer from './Footer';

const Orders = () => {
  const { user } = useMyContext(); // Make sure 'user' is correctly fetched from the context
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  console.log('User:', user); // Check user details

  // Fetch orders from the API
  useEffect(() => {
    // Fetch orders only if user is defined
    if (user && user.email) {
      const fetchOrders = async () => {
        try {
          console.log('Fetching orders for user:', user.email); // Log user email before fetch
          const response = await fetch(
            `https://id4f34hzse.execute-api.ap-south-1.amazonaws.com/default/orders?user_id=${user.email}`
          );

          console.log('Response status:', response.status); // Log response status
          const data = await response.json();

          if (response.ok) {
            setOrders(data);
          } else {
            setError('Failed to fetch orders');
          }
        } catch (err) {
          console.error('Error fetching orders:', err);
          setError('An error occurred while fetching orders');
        } finally {
          setLoading(false);
        }
      };

      fetchOrders();
    } else {
      console.log('User not defined or user email is missing.'); // Log if user is not defined
      setLoading(false); // Stop loading if user is not defined
    }
  }, [user]); // Add user as a dependency so it triggers only after user is available

  if (loading) return <p>Loading orders...</p>;
  if (error) return <p>{error}</p>;

  return (
        <>
    <div className="orders-container">
        <Navbar/>
      <h2>Your Orders</h2>
      {orders.length > 0 ? (
        <ul>
          {orders.map((order) => (
            <li key={order.order_id}>
              <strong>Order ID:</strong> {order.order_id} <br />
              <strong>Total Amount:</strong> ₹{order.total_amount / 100}
            </li>
          ))}
        </ul>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
    <Footer/>
    </>
  );
};

export default Orders;
