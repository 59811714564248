import React from "react";
import { useGlobalHandlers } from "./GlobalHandlersContext";

function Product({ item }) {
  const { handleClick } = useGlobalHandlers();

  const { id, name, offer_price, photos } = item;
  return (
    <div className="product" href={"/products/" + id}>
      <div className="product-img">
        <img src={photos[0]} alt="" loading="lazy" />
      </div>
      <div className="product-card">
        <div className="product-title">
          <h6>{name}</h6>
        </div>
        <div className="product-details">
          <p>
            <span> &#8377; {offer_price}</span>
          </p>
        </div>



        <div className="product-offer">
          <span>OFFER</span>
          <small>save 20%</small>
        </div>
        <div className="product-btn">
          <button >Buy Now</button>
          <button onClick={() =>
            {handleClick(item);
          }} >Add Cart</button>
        </div>
      </div>
    </div>
  );
}

export default Product;
